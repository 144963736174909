html {
  box-sizing: border-box;
  font-size: 6.25%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
}

menu,
ol,
ul {
  padding: 0;
}

li {
  list-style: none;
}

body {
  margin: 0;
  font-family: 'BlissPro', sans-serif;
  font-size: 16em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}
